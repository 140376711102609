const { devMode, projectName, domain } = require('./commonSettings')();

module.exports = () => {
  const settings = {
    // Project
    projectName,
    devMode,

    // Endpoints, Ports, and Origins
    websiteOrigin: `https://${domain}`,
    hasuraOrigin: `https://${domain}`,
    kiskaOrigin: `https://${domain}`,
    localKiskaApiEndpoint: `http://${projectName}_kiska:7227/v1`,
    localGraphqlApiEndpoint: `http://${projectName}_hasura:9598/v1/graphql`,

    // Logging
    log: {
      logger: devMode ? 'none' : 'rollbar',
      rollbar: {
        accessToken: '51c36303748f4a05847d44cb6d3de530',
      },
    },

    // Auth
    auth: {
      mode: 'auth0',
      userGroup: `${projectName}`,
      fileUploadRoles: ['admin', 'manager', 'employee', 'office-admin', 'contractor'],
      auth0: {
        domain: 'auth.slick.systems',
        clientId: 'fRGfEyqkDoqCodoogjHMXBfSZH2kQdVj',
        jwkUrl: 'https://auth.slick.systems/.well-known/jwks.json',
        jwtClaimsNamespace: 'https://hasura.io/jwt/claims',
      },
    },
  };

  return settings;
};
